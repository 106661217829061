import React, { useEffect, useState } from "react";
import CustomerService from "../services/CustomerServices";
import { notifySuccess, notifyError } from "../utils/toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeUser, setUser } from "../Store/reducers/authSlice";
import { setNotifyMessage } from "../Store/reducers/notifySlice";
import { clearCarts } from "../Store/reducers/cartSlice";
import Swal from 'sweetalert2';

const useAuth = () => {
  const [registerEmail, setRegisterEmail] = useState("");
  const [showRegister, setShowRegister] = useState(false);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((store) => store?.auth?.userInfo);

  const handleSetShowRegister = () => setShowRegister(true);

  const handleLoginSubmit = async (e) => {
    try {
      e.preventDefault();

      const loginData = {
        registerEmail: e.target.loginEmail.value,
        password: e.target.loginPassword.value,
      };

      const resData = await CustomerService.loginCustomer(loginData);

      if (!resData.verified) {
        // notifyError("User is not Verified, Kindly Contact the Alma Team for Verification !!");
        dispatch(setNotifyMessage("User is not Verified, Kindly Contact the Alma Team for Verification !!"))
        return;
      }

      Cookies.set("userInfo", JSON.stringify(resData));
      dispatch(setUser(resData));

      // notifySuccess("User logged in successfully.");
      dispatch(setNotifyMessage("User logged in successfully."));
      Navigate("/");

    } catch (error) {
      // notifyError(error?.response?.data?.message);
      dispatch(setNotifyMessage(error?.response?.data?.message));
    }
  };

  const handleRegisterEmail = (e) => {
    e.preventDefault();
    setRegisterEmail(e.target.registerEmail.value);
  };

  const handleRegisterSubmit = async (e) => {
    try {
      e.preventDefault();

      const { registerFullName, registerEmail, registerCompanyName, registerCountry, registerInsta, registerPassword, registerConfirmPassword, checkNewsletter, checkTermsCondtion } = e.target;

      const registerData = {
        name: registerFullName.value?.trim(),
        email: registerEmail.value?.trim(),
        companyName: registerCompanyName.value?.trim(),
        country: registerCountry.value?.trim(),
        instagram: registerInsta.value.replace(/^@+/g, ''),
        password: registerPassword.value?.trim(),
        confirmPassword: registerConfirmPassword.value?.trim(),
        newsletter: checkNewsletter.checked,
        checkTermsCondtion: checkTermsCondtion.checked,
      };

      if (registerData.password?.trim() !== registerData.confirmPassword?.trim()) {
        return dispatch(setNotifyMessage("Password And Confirm Password is Not Same !!"));
        // notifyError("Password And Confirm Password is Not Same !!");
      }

      if (!registerData.checkTermsCondtion) {
        return dispatch(setNotifyMessage("Terms and Condition is Not Selected!!"));
        // notifyError("Terms and Condition is Not Selected!!");
      }


      const registerRes = await CustomerService.verifyEmail(registerData);

      e.target.reset();
      setRegisterEmail("");

      // notifySuccess(registerRes?.message);
      Swal.fire({
        text: registerRes?.message,
        icon: "success"
      });
      // dispatch(setNotifyMessage(registerRes?.message));

    } catch (error) {
      // notifyError(error?.response?.data?.message);
      dispatch(setNotifyMessage(error?.response?.data?.message));
    }
  };

  const handleForgotPassword = async (e) => {
    try {
      e.preventDefault();

      const forgotPasswordData = {
        verifyEmail: e.target.loginEmail.value,
      };

      const resData = await CustomerService.forgetPassword(forgotPasswordData);
      // notifySuccess(resData.message);
      dispatch(setNotifyMessage(resData.message));


      Navigate("/");
    } catch (error) {
      // notifyError(error?.response?.data?.message);
      dispatch(setNotifyMessage(error?.response?.data?.message));
    }
  }

  const handleResetPassword = async (e, token) => {
    try {
      e.preventDefault();

      const password = e.target.password.value;
      const confirmPassword = e.target.confirmPassword.value;

      if (password.trim() !== confirmPassword.trim()) {
        // notifyError("Password And Confirm Password is not Same !!");
        dispatch(setNotifyMessage("Password And Confirm Password is not Same !!"));
        return;
      }

      const ResetPasswordData = {
        token: token,
        newPassword: e.target.confirmPassword.value,
      };

      const resData = await CustomerService.resetPassword(ResetPasswordData);
      // notifySuccess(resData.message);
      dispatch(setNotifyMessage(resData.message));


      Navigate("/");
    } catch (error) {
      console.log(error);
      // notifyError(error?.response?.data?.message);
      dispatch(setNotifyMessage(error?.response?.data?.message));
      
    }
  }

  const verifyEmail = async (token) => {

    try {

      const resData = await CustomerService.registerCustomer(token);

      // Cookies.set("userInfo", JSON.stringify(resData));
      // dispatch(setUser(resData));
      // notifySuccess('User Registration Successfully !!');
      Swal.fire({
        text: "User Registration Successfully, Contact the Alma Team for Account Verification !!",
        icon: "success"
      });
      // dispatch(setNotifyMessage("User Registration Successfully, Contact the Alma Team for Account Verification !!"));

    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateUser = async (e) => {
    try {
      e.preventDefault();

      const registerData = {
        name: e.target.fullName.value,
        email: e.target.email.value,
        companyName: e.target.companyName.value,
        country: e.target.country.value,
        instagram: e.target.instagram.value,
        password: e.target.password.value,
        newPassword: e.target.newPassword.value,
        confirmPassword: e.target.confirmPassword.value,
        // newsletter: e.target.newsletter.checked,
        // checkTermsCondtion: e.target.checkTermsCondtion.checked,
      };

      if (registerData.newPassword !== registerData.confirmPassword) {
        return dispatch(setNotifyMessage("New Password And Confirm Password is Not Same !!"));
          // notifyError("New Password And Confirm Password is Not Same !!");
      }

      // if (!registerData.checkTermsCondtion) {
      //   return notifyError("Terms and Condition is Not Selected!!");
      // }

      const registerRes = await CustomerService.updateCustomer(userInfo._id, registerData);

      e.target.reset();

      Cookies.set("userInfo", JSON.stringify(registerRes));

      dispatch(setUser(registerRes));

      // notifySuccess("User Updated Successfully !!");
      dispatch(setNotifyMessage("User Updated Successfully !!"));


    } catch (error) {
      // notifyError(error?.response?.data?.message);
      dispatch(setNotifyMessage(error?.response?.data?.message));
    }
  }

  const handleLogOut = () => {
    Cookies.remove("userInfo");
    dispatch(removeUser());
    dispatch(clearCarts());
    // notifySuccess("User logged out successfully");
    dispatch(setNotifyMessage("User logged out successfully"));

    Navigate("/");
  }

  return {
    handleLoginSubmit,
    handleRegisterSubmit,
    handleRegisterEmail,
    registerEmail,
    setRegisterEmail,
    showRegister,
    handleSetShowRegister,
    verifyEmail,
    handleUpdateUser,
    handleLogOut,
    handleForgotPassword,
    handleResetPassword
  };
};
export default useAuth;
